<template>
    <div class="s-toggle rounded-full inline-flex text-black border-2 border-gray-200">
        <button v-for="(opt, i) in options" :key="i" type="button" :aria-pressed="current === i ? 'true' : 'false'"
            class="s-toggle-button rounded-full py-1.5 px-4 font-medium select-none w-6/12" @click="select(i)">
            {{ opt }}
        </button>
    </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        options: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...mapGetters('shop', ['getSelectedOption']), // Map Vuex getter
        current() {
            return this.getSelectedOption; // Derive the current value from Vuex state
        },
    },
    mounted() {
        // Retrieve persisted value from localStorage
        const persistedOption = localStorage.getItem('selectedOption');

        if (persistedOption !== null) {
            this.setSelectedOption(persistedOption);
        } else if (this.current === undefined || this.current === null) {
            // If no value in Vuex or localStorage, initialize to the first option
            this.setSelectedOption('delivery');
        }
    },
    methods: {
        ...mapActions('shop', ['setSelectedOption']), // Map Vuex action
        select(i) {
            this.setSelectedOption(i); // Update Vuex state
            localStorage.setItem('selectedOption', i); // Persist the selected value
            this.$emit('selectAcquisitionOption', i); // Emit the selected option
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-toggle {
    @apply text-sm py-0.5 px-0.5;

    .s-toggle-button {
        &[aria-pressed='true'] {
            @apply bg-pink-200 text-black;
        }
    }
}

.s-toggle--dark {
    @apply text-white border-2 border-white;
}

.s-toggle--enlarged {
    @apply text-base py-1 px-1;

    .s-toggle-button {
        @apply py-1.5 px-5;

        &[aria-pressed='true'] {
            @apply shadow-lg;
        }
    }
}
</style>
